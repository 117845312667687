/* eslint-disable no-tabs */

// import c from "classnames";

import { GetStaticProps } from "next";
import { v1 as uuidv1 } from "uuid";
// import Image from "next/image";

import { Layout } from "@/components/Layout";
import { PageData, TableSectionProps } from "@/types/page";
import { makeOrgSchema } from "@/helpers/schema";
import { getLandingPageQuery } from "@/API/queries";
import { Hero } from "@/components/pageSections/Hero";
import { _Head } from "@/components/_head/_head";
import { ImageAndText } from "@/components/pageSections/ImageAndText";
import { BenefitsBar } from "@/components/pageSections/BenefitsBar";
import { DietComparisonContent } from "@/components/pageSections/DietComparison";
import { ReviewsSection } from "@/components/pageSections/ReviewsSection";
import { FullWidth } from "@/components/pageSections/FullWidth";
import { initializeApollo } from "@/API/apolloClient";
import { Table } from "@/components/pageSections/Table";
import { Timeline } from "@/components/pageSections/Timeline";
import { SuccessStories } from "@/components/pageSections/SuccessStories";
import { Products } from "@/components/pageSections/Products";
import { Quotes } from "@/components/pageSections/Quotes";
import { SocialVideos } from "@/components/pageSections/SocialVideos/SocialVideos";

export const getStaticProps: GetStaticProps = async ({ preview }) => {
	try {
		const apolloClient = initializeApollo();
		const { data } = await apolloClient.query({
			query: getLandingPageQuery(!!preview),
			variables: {
				slug: "home",
			},
			context: { clientName: preview === true
				? "datoPreview"
				: "dato" },
		});

		if (!data.allNewLandingPages || data.allNewLandingPages.length < 1) {
			return {
				notFound: true,
			};
		}

		return {
			props: {
				pageData: {
					...data.allNewLandingPages[0],
					sections: data.allNewLandingPages[0].sections.map((item: any) => {
						return {
							...item,
							id: item.id || uuidv1(),
						};
					}),
				},
			},
		};
	} catch (err) {
		console.log(err);
		
		return { notFound: true };
	}
};

const Page = ({ pageData }: { pageData: PageData }) => {
	const pageDataSections = pageData.sections.map((section) => {
		switch (section.__typename) {
		case "LandingHeroRecord":
			return <Hero content={section} key={section.id} page="homepage" />;
		case "LandingImageTextRecord":
			return <ImageAndText content={section} key={section.id} />;
		case "LandingBenefitsBarRecord":
			return <BenefitsBar content={section} key={section.id} />;
		case "LandingDietComparisonRecord":
			return <DietComparisonContent id={section.id} key={section.id} />;
		case "LandingReviewRecord":
			return (
				<ReviewsSection hasPet={false} content={section} key={section.id} />
			);
		case "LandingFullWidthRecord":
			return <FullWidth content={section} key={section.id} />;
		case "LandingQuoteRecord":
			return <Quotes content={section} key={section.id} />;
		case "LandingProductRecord":
			return <Products content={section} key={section.id} />;
		case "LandingSuccessStoriesContentRecord":
			return <SuccessStories content={section} key={section.id} />;
		case "LandingTimelineRecord":
			return <Timeline content={section} key={section.id} />;
		case "LandingTableRecord":
			return (
				<Table
					content={section as unknown as TableSectionProps}
					key={section.id}
				/>
			);
		case "LandingChooseTableRecord":
			return <Table content={section.table.table[0]} key={section.id} />;

		case "LandingSocialVideoRecord":
			return <SocialVideos {...section} />;
		default:
			return <></>;
		}
	}, []);

	return (
		<Layout voucher={pageData.autoAppliedVoucher.voucher} hasFooter slug={pageData.slug}>
			{pageData.metadata && (
				<_Head
					title={pageData.metadata[0].title + " - Pure Pet Food UK"}
					metaDescription={pageData.metadata[0].description as string}
					metaOGTitle={pageData.metadata[0].title as string}
					metaOGDescription={pageData.metadata[0].description as string}
				>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(makeOrgSchema()),
						}}
					/>
				</_Head>
			)}

			{pageDataSections}
		</Layout>
	);
};
// Nextjs
// eslint-disable-next-line import/no-default-export
export default Page;
